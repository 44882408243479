<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      v-if="!drawer"
      @click="drawer = !drawer"
    >
      <v-icon >
        mdi-menu
      </v-icon>

    </v-btn>


    <!-- for the home route we show a "Welcome" message -->
    <v-toolbar-title v-if="$route.name == 'home'"
      class="hidden-sm-and-down font-weight-bold"
      v-text="$t('labels.welcome') + ' ' + user.firstName"
    />

    <!-- otherwise the name of the route -->
    <v-toolbar-title v-else
      class="hidden-sm-and-down font-weight-bold"
      v-text="$t('routes.' + $route.name + '.title')"
    />

    <v-spacer />
<!--
    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

   
<v-spacer />
    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

     -->

     <change-lang-btn />

<v-btn
      class="ml-2"
      min-width="0"
      text
      to="support"
    >
      <v-icon>mdi-help-circle</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="userProfile"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="logout"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  import Question from '@/components/Question'
  import ChangeLangBtn from '@/components/ChangeLangBtn'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      'change-lang-btn': ChangeLangBtn,
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),

    computed: {
      user() { return this.$store.getters.user },
      drawer: {
        get () {
          return this.$store.getters.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
    },

    methods: {
      logout(){
        const data = {
          title: this.$t('actions.logout.title'), 
          question: this.$t('actions.logout.message')
        }
        this.$openModal(Question, data, this).then(() => {
          
          // because we will probably switch user
          // clear full localstorage as this contain user specific info


          localStorage.clear();
          window.location.reload()
          
        }) 
      },
      goToHelp() {
        window.location = 'https://www.paybonsai.com/contact'
      }
    }
  }
</script>
